<template>
  <base-dialog customActivator :title="$t('redeemPrize')">
    <template #custom-activator="{ events: { handleOpenDialog } }">
      <slot name="list-prizes" :dialogEvents="{ handleOpenDialog }" />
    </template>

    <template #content>
      <h4 class="text-center text-h6">{{ $t("redeemQuestionConfirm") }}</h4>
    </template>

    <template
      #actions="{ events: { handleCloseDialog } }"
      class="flex-column flex-sm-row flex-wrap justify-space-between"
    >
      <base-btn
        color="blue-grey"
        class="btn-action my-2 mx-0 white--text"
        @click="handleCloseDialog"
      >
        {{ $t("back") }}
      </base-btn>
      <base-btn
        :loading="sendingRedeemPrize"
        :disabled="sendingRedeemPrize"
        color="success"
        class="btn-action my-2 mx-0"
        @click="() => handleRedeemPrize(handleCloseDialog)"
      >
        {{ $t("redeemConfirm") }}
      </base-btn>
    </template>
  </base-dialog>
</template>

<script>
import { i18n } from "@/plugins/i18n";
import { prizesCtrl } from "@/controllers";
import { useSnackbar } from "@/utils";
import { getErrorPayloadForSnackbar } from "@/helpers";

export default {
  name: "RedeemPrizeDialog",
  props: {
    prizeSelectedToRedeem: { type: Object, required: true, default: null },
  },
  data() {
    return {
      sendingRedeemPrize: false,
    };
  },
  methods: {
    async handleRedeemPrize(handleCloseDialog) {
      try {
        this.sendingRedeemPrize = true;
        const { message } = await prizesCtrl.redeemPrize(
          this.prizeSelectedToRedeem.id
        );
        const snackbar = useSnackbar();
        snackbar.dispatchOpenSnackbar(message, i18n.t("successRedeemPrize"));
        handleCloseDialog && handleCloseDialog();
        this.$emit("prizeRedeemed");
      } catch (error) {
        const errorPayload = getErrorPayloadForSnackbar(error);
        this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
      } finally {
        this.sendingRedeemPrize = false;
      }
    },
  },
};
</script>
